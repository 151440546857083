.square {
    background-color: rgb(156, 250, 255);
    width: 160px;
    height: 160px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5em;
    color:rgb(193, 128, 253)
}